<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: ESM - EASY SCHOOL MANAGEMENT
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- ROW 1-->
    <div class="vx-row">
       
        <div class="vx-col w-full mb-base">
          <div class="vx-row" v-if="user_role !=='agent'">

            <div class="cursor-pointer vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="owners()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ClipboardIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.owners || 0) )"
                :statisticTitle="$t('Inscrits')"/>
            </div>
            <div class="cursor-pointer vx-col w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2" @click="agents()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.agents || 0) )"
                :statisticTitle="$t('Agents')"/>
            </div>

          
           <!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>
       </div>
    </div> 

  </div>
</template>

<script>


import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from '@/views/ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'
import ChartjsBarChart from '/src/views_moozimed/statistiqueGraph/ChartjsBarChart'

export default {
  data () {
    return {
      user_role: '',
      dashboardValue: false,
      showByContributors: false,
      variable_dashboard : '',
      analyticsData,
      filter_appointment:null
    }
  },

  components: {
    VueApexCharts,
    ChartjsBarChart,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
 
  methods: {
    agents () {
      this.$router.push('/Agents').catch(() => {})
    },

    owners () {
      this.$router.push('/Inscrits').catch(() => {})
    },
    dashboard () {
      this.$vs.loading()
      this.$http.get('dashboardAdmin/')
        .then((response) => {
          this.variable_dashboard = response.data
          this.$vs.loading.close()
        })
    },
 
    dashboardRefrech () {
      this.$http.get('dashboardAdmin/')
        .then((response) => {
          this.variable_dashboard = response.data
        })
    }
  },
  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    if (this.user_role !== 'agent') {
      this.dashboard()
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech()
        }.bind(this), 60000)
    }
  },
  destroyed () {
    clearInterval(this.counterInterval)
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
